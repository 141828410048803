import React from 'react'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { config, useSpring } from 'react-spring'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#000">
      <SEO title="About | Solipsis Development" desc="Hi. We're Solipsis Development." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Hi. We're Solipsis Development!</h1>
        <p>
          We're an independent VR studio operating in Los Angeles, CA.<br/>
          These are the <strike>mad scientists</strike> engineers responsible.
        </p>
        <h3>Jon Monroe - Head of All the Things</h3>
        <p>
          Born in Durham, North Carolina, Jon has been tinkering with computers since he was a little kid.  He has been programming since he was 12.  He went to UNC Chapel Hill for Computer Science from which he graduated with distinction.
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
